import Vue from 'vue';

Vue.directive('columns-resizable', {
    inserted(el, binding) {
        const nodeName = el.firstElementChild?.nodeName;
        if (!['TABLE', 'THEAD'].includes(nodeName)) return;

        const table = nodeName === 'TABLE' ? el : el.parentElement;
        const thead = table.querySelector('thead');
        const ths = thead.querySelectorAll('th');

        const localStorageKey = binding.value || null;

        if (!localStorageKey) return;

        const resizeContainer = document.createElement('div');
        table.style.position = 'relative';
        resizeContainer.style.position = 'absolute';
        resizeContainer.style.width = table.offsetWidth + 'px';
        resizeContainer.className = "vue-columns-resizable";
        table.parentElement.insertBefore(resizeContainer, table);

        let moving = false;
        let movingIndex = 0;

        const loadWidthsFromLocalStorage = () => {      
            const savedWidths = JSON.parse(localStorage.getItem(localStorageKey));
            if (savedWidths && savedWidths.length === ths.length) {
                ths.forEach((th, index) => {
                    th.style.width = savedWidths[index] + 'px';
                });
            }
        };

        const saveWidthsToLocalStorage = () => {
            if (!localStorageKey) return;
            
            const widths = Array.from(ths).map(th => th.offsetWidth);
            localStorage.setItem(localStorageKey, JSON.stringify(widths));
        };

        loadWidthsFromLocalStorage();

        const createBars = () => {
            resizeContainer.innerHTML = '';
            ths.forEach((th, index) => {
                th.style.width = th.offsetWidth + 'px';

                if (index + 1 >= ths.length) return;

                const nextTh = ths[index + 1];
                const bar = document.createElement('div');

                bar.style.position = 'absolute';
                bar.style.left = nextTh.offsetLeft - 4 + 'px';
                bar.style.top = 0;
                bar.style.height = table.offsetHeight + 'px';
                bar.style.width = '8px';
                bar.style.cursor = 'col-resize';
                bar.style.zIndex = 1;
                bar.className = 'columns-resize-bar';

                bar.addEventListener('mousedown', () => {
                    moving = true;
                    movingIndex = index;
                    document.body.style.cursor = 'col-resize';
                    document.body.style.userSelect = 'none';
                });

                resizeContainer.appendChild(bar);
            });
        };

        createBars();

        const bars = () => resizeContainer.querySelectorAll('.columns-resize-bar');

        document.addEventListener('mouseup', () => {
            if (!moving) return;
            moving = false;
            document.body.style.cursor = '';
            document.body.style.userSelect = '';

            bars().forEach((bar, index) => {
                const th = ths[index];
                const nextTh = ths[index + 1];
                th.style.width = th.offsetWidth + 'px';
                bar.style.left = nextTh.offsetLeft - 4 + 'px';
            });

            saveWidthsToLocalStorage();
        });

        const handleResize = e => {
            if (moving) {
                const th = ths[movingIndex];
                const nextTh = ths[movingIndex + 1];
                const bar = bars()[movingIndex];

                th.style.width = parseInt(th.style.width) + e.movementX + 'px';
                nextTh.style.width = parseInt(nextTh.style.width) - e.movementX + 'px';
                bar.style.left = nextTh.offsetLeft - 4 + e.movementX + 'px';
            }
        };

        resizeContainer.addEventListener('mousemove', handleResize);
        table.addEventListener('mousemove', handleResize);

        const recalculateBars = () => {
            resizeContainer.style.width = table.offsetWidth + 'px';
            createBars();

            const totalColumnsWidth = Array.from(ths).reduce((sum, th) => sum + th.offsetWidth, 0);
            const tableWidth = table.offsetWidth;

            if (totalColumnsWidth > tableWidth) {

                const scaleFactor = tableWidth / totalColumnsWidth;
                ths.forEach(th => {
                    th.style.width = (parseFloat(th.style.width) * scaleFactor) + 'px';
                });
                createBars();
            }

            saveWidthsToLocalStorage();
        };
        
        window.addEventListener('resize', recalculateBars);

        el.__resizeObserver__ = recalculateBars;
    },

    unbind(el) {
        window.removeEventListener('resize', el.__resizeObserver__);
        delete el.__resizeObserver__;
    }
});