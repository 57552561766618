<template>
  <div>
    <b-table
      :items="stItems"
      :fields="fields"
      :sort-by.sync="stSortBy"
      :sort-desc.sync="stSortDesc"
      :busy="loading"
      :responsive="responsive"
      :hover="hover"
      :per-page="rowsPerPage"
      :current-page="currentPage"
      :filter="filter"
      fixed
      :head-variant="headVariant"
      :table-variant="tableVariant"
      :thead-class="headClass"
      thead-tr-class="st-data-table-head-row"
      tbody-class="st-data-table-body"
      :tbody-tr-class="rowClass"
      :class="tableClass"
      show-empty
      :empty-text="emptyText"
      :selectable="selectable"
      :select-mode="selectMode"
      :actionsClass="actionsClass"
      @row-selected="onRowSelected"
      :no-select-on-click="noSelectOnClick"
      @row-clicked="onRowClicked"
      @sort-changed="sortingChanged"
      :no-local-sorting="serverSideSorting"
      sort-icon-left
      ref="selectableTable"
      selected-variant=""
      v-columns-resizable="persistanceIdentifier ? `${persistanceIdentifier}_${currentUser.id}_resize` : null"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-3">{{ $t("GENERAL.LOADING") }}</strong>
        </div>
      </template>
      <template #table-colgroup="scope" v-if="hasIconDefined()">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'Icons' ? '80px' : '' }"
        />
      </template>
      <template #table-colgroup="scope" v-else>
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: getColWidth(field) }"
        />
      </template>
      <template #head()="data">
        <span v-if="data.column === 'selected' && allowSelectAll">
          <input type="checkbox" name="selected" v-model="selectAll" />
        </span>
        <span class="st-header-text" v-else>{{ data.label.toUpperCase() }}</span>
      </template>

      <template #cell(Icons) v-if="hasIconDefined()">
        <span class="st-data-table-icon svg-icon svg-icon-md">
          <span v-if="firstColumnIcons.customIcon" v-html="firstColumnIcons.name"></span>
          <i
            v-else
            :class="`text-${firstColumnIcons.type} fas fa-${firstColumnIcons.name}`"
          ></i>
        </span>
      </template>
      <template #cell(selected)="row" v-if="hasSelectedColumnDefined">
        <input
          type="checkbox"
          v-model="row.item.selected"
          v-if="showCheckbox(row.item)"
          :disabled="row.item.locked"
          name="selected"
          @change="checkRow(row)"
        />
      </template>
      <template #cell()="data">
        <span
          v-if="!!data.field.booleanType"
          class="label custom-label"
          :class="{
            'label-success': data.value,
            'label-text-muted': !data.value,
          }"
        >
          <i v-if="data.value" class="fas fa-check text-light"></i>
          <i v-else class="fas fa-check text-light"></i>
        </span>
        <span v-else-if="data.field.labelType && data.value" class="text-truncate">
          <span v-for="(item, i) in data.field.options" :key="i">
            <span v-if="item.name === data.value">
              <span
                v-if="!!item.text"
                :class="`label label-xl label-inline label-light-${item.type}`"
              >
                {{ item.text }}
              </span>
              <span
                v-else
                :class="`label label-xl label-inline label-light-${item.type}`"
              >
                {{ data.value }}
              </span>
            </span>
          </span>
        </span>
        <span v-else-if="data.field.labelType && !data.value" class="text-truncate">
          <span class="label label-xl label-inline label-light-dark"> - </span>
        </span>
        <span v-else-if="data.field.linkable">
          <b-link @click.self.prevent="goToLink(data)">{{ data.value }}</b-link>
        </span>
        <span v-else-if="data.field.password">
          <span class="d-block text-truncate">{{
            ``.padStart(data.value.length, "*")
          }}</span>
        </span>
        <span v-else-if="data.field.customField">
          <component
            :is="data.field.portalTarget"
            :presenter="presenter(data)"
            :dataObj="data"
          />
        </span>
        <div v-else>
          <span
            :class="['d-block text-truncate', { 'font-weight-bold': !!data.field.bold }]"
          >
            {{ presenter(data) }}
          </span>
        </div>
      </template>
      <template #cell(Actions)="data" v-if="actionsList && actionsList.length > 0">
        <span v-for="(action, i) in actionsList" v-bind:key="i">
          <span v-if="action.displayed">
            <span v-if="!(action.hideOnRow && data.item.hideAction)">
              <b-button
                v-if="!!action.buttonType"
                size="sm"
                @click="doAction(action.name, data)"
                variant="primary"
                >{{ action.buttonText }}
              </b-button>
              <b-button
                v-else
                :class="`btn btn-icon btn-light btn-hover-${action.type} st-btn-data-table`"
                @click="doAction(action, data)"
                v-b-tooltip.hover="{ variant: 'info' }"
                :title="action.tooltipText"
                :disabled="!!action.disabled"
              >
                <span
                  :class="`st-data-table-icon svg-icon svg-icon-md  svg-icon-${action.type}`"
                >
                  <span v-if="action.customIcon" v-html="action.icon"></span>
                  <i v-else :class="`fas fa-${action.icon}`"></i>
                </span>
              </b-button>
            </span>
            <span v-else-if="data.item.hideActionName === action.name">
              <b-button
                :class="`btn btn-icon btn-light btn-hover-${action.type} st-btn-data-table`"
                @click="doAction(action, data)"
                v-b-tooltip.hover="{ variant: 'info' }"
                :title="action.tooltipText"
                :disabled="!!action.disabled"
              >
                <span
                  :class="`st-data-table-icon svg-icon svg-icon-md  svg-icon-${action.type}`"
                >
                  <span v-if="action.customIcon" v-html="action.icon"></span>
                  <i v-else :class="`fas fa-${action.icon}`"></i>
                </span>
              </b-button>
            </span>
          </span>
        </span>
      </template>
      <template v-slot:head(columnSelector)>
        <b-button id="columnSelectorButton" pill variant="outline" size="sm">
          <b-icon-list-check font-scale="1.5"></b-icon-list-check>
        </b-button>
        <b-popover
          target="columnSelectorButton"
          triggers="click"
          :show.sync="showColumnSelector"
          placement="bottomleft"
          container="my-container"
          ref="popover"
        >
          <template #title> Selecteaza coloanele vizibile </template>
          <div>
            <div
              class="d-flex flex-row justify-content-between align-items-center mb-3"
              v-for="configurableField in configurableFields"
              :key="configurableField.key"
            >
              <b-form-checkbox
                :checked="selectedConfigurableFields.includes(configurableField.key)"
                @change="handleColumnSelected($event, configurableField.key)"
                class="mr-4"
              >
                {{ configurableField.label }}
              </b-form-checkbox>
              <b-badge
                v-if="selectedConfigurableFields.includes(configurableField.key)"
                pill
                variant="primary"
                >{{
                  selectedConfigurableFields.indexOf(configurableField.key) + 1
                }}</b-badge
              >
            </div>
            <div>
              <b-button
                @click="applyColumnSelector"
                size="sm"
                variant="primary"
                class="mr-2"
                >Aplica</b-button
              >
              <b-button
                @click="selectedConfigurableFields = []"
                size="sm"
                variant="light"
                class="mr-2"
                >Reseteaza</b-button
              >
              <b-button @click="closeColumnSelector" size="sm" variant="dark"
                >Inchide</b-button
              >
            </div>
          </div>
        </b-popover>
      </template>
      <template v-slot:cell(columnSelector)> </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "@/shared/plugins/vue-i18n";
import { isObjectEmpty } from "@/core/helpers/globalMethods";
import EnableTruncate from "../components/data-table/DataTableTruncateField";
import BeneficiaryCustomField from "../components/data-table/BeneficiaryCustomField";
import CategoryUsageTypeCustomField from "../components/data-table/CategoryUsageTypeCustomField";
import LocalityCustomField from "../components/data-table/LocalityCustomField";
import {
  BSpinner,
  VBTooltip,
  BIconListCheck,
  BPopover,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";
import Message from "@/shared/message/message";

export default {
  name: "StDataTable",
  components: {
    "b-spinner": BSpinner,
    EnableTruncate,
    BeneficiaryCustomField,
    CategoryUsageTypeCustomField,
    LocalityCustomField,
    BIconListCheck,
    BPopover,
    BFormCheckbox,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    configurableFields: {
      type: Array,
      required: false,
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: String,
      default: "md",
    },
    hover: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    filter: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    headVariant: {
      type: String,
      default: "light",
    },
    tableVariant: {
      type: String,
      default: "light",
    },
    actionsLabel: {
      type: String,
      default: i18n.t("GENERAL.TABLE.ACTIONS"),
    },
    modelPresenter: {
      type: Function,
    },
    selectMode: {
      type: String,
      default: "single", // other options: multi, range
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    firstColumnIcons: {
      type: Object,
      default: () => ({}),
    },
    headClass: {
      type: String,
      default: "st-data-table-head",
    },
    actionsClass: {
      type: String,
    },
    tbodyTrClass: {
      type: String,
      default: "st-data-table-body-row",
    },
    noSelectOnClick: {
      type: Boolean,
      default: false,
    },
    treeView: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: i18n.t("GENERAL.TABLE_EMPTY_TEXT"),
    },
    firstColumnSelectable: {
      type: Boolean,
      default: false,
    },
    stateModule: String,
    serverSideSorting: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    allowSelectAll: {
      type: Boolean,
      default: false,
    },
    customBooleanTypeField: {
      type: Boolean,
      default: false,
    },
    checkLimit: {
      type: Number,
    },
    checkLimitMessage: {
      type: String,
    },
    persistanceIdentifier: {
      type: String,
    },
  },
  data() {
    return {
      showColumnSelector: false,
      stFields: [],
      actionsList: this.actions.map((action) => {
        return {
          ...action,
          displayed: typeof action.permission === "boolean" ? action.permission : true,
        };
      }),
      selectAll: false,
      snapshotSelectedConfigurableFields: [],
      selectedConfigurableFields: [],
    };
  },
  computed: {
    ...mapGetters({
      appConfig: "shared/appConfig",
      currentUser: "auth/currentUser",
    }),
    rowsPerPage() {
      return this.perPage || this.appConfig.RECORDS_PER_PAGE;
    },
    hasSelectedColumnDefined() {
      return this.firstColumnSelectable;
    },
    storedSortData() {
      return this.stateModule ? this.$store.getters[`${this.stateModule}/sorting`] : {};
    },
    stSortBy: {
      get() {
        return this.storedSortData?.sortBy ?? this.sortBy;
      },
      set(val) {
        this.$emit("sortBy", val);
      },
    },
    stSortDesc: {
      get() {
        return this.storedSortData?.sortDesc ?? this.sortDesc;
      },
      set(val) {
        this.$emit("sortDesc", val);
      },
    },
    stItems() {
      if (this.hasSelectedColumnDefined) {
        return this.items.map((item) => ({ ...item, selected: false }));
      }

      return this.items;
    },
    tableClass() {
      let cssClass = "st-data-table";
      if (this.customClass) {
        cssClass += ` ${this.customClass}`;
      }
      return cssClass;
    },
  },
  watch: {
    selectAll(val) {
      if (val) {
        this.stItems.forEach((item) => {
          if (!item.locked) item.selected = true;
        });

        const selectableRows = this.selectableRows(this.stItems);
        if (this.checkLimit && selectableRows.length > this.checkLimit) {
          Message.error(this.checkLimitMessage, { number: this.checkLimit });
        }
      } else {
        const selectedRows = this.selectedRows(this.stItems);
        const lockedRows = this.lockedRows(this.stItems);

        if (selectedRows.length + lockedRows.length === this.items.length) {
          this.stItems.forEach((item) => {
            item.selected = false;
          });
        }
      }
      this.$emit(
        "itemChecked",
        this.checkLimit
          ? this.selectableRows(this.stItems)
          : this.selectedRows(this.stItems)
      );
    },
  },
  created() {
    this.manageActionsColumn();
    if (this.configurableFields && this.configurableFields.length > 0) {
      this.fields.push({
        key: "columnSelector",
        label: "",
        thStyle: { width: "50px" },
      });

      if (this.persistanceIdentifier) {
        const persistedFields = JSON.parse(
          localStorage.getItem(`${this.persistanceIdentifier}_${this.currentUser.id}`) ||
            "[]"
        );
        if (persistedFields && persistedFields.length > 0) {
          this.snapshotSelectedConfigurableFields = [...persistedFields];
          this.selectedConfigurableFields = [...this.snapshotSelectedConfigurableFields];
          this.applyColumnSelector();
        } else {
          this.populateSelectedConfigurableFieldsSnapshot();
        }
      } else {
        this.populateSelectedConfigurableFieldsSnapshot();
      }
    }
  },
  methods: {
    ...mapActions({
      setSortingParams: "shared/fetchSortingParams",
    }),
    doAction(action, data) {
      if (action.name !== "delete") {
        this.$emit(`${action.name}`, data);
      } else {
        // customDeleteMsg can be defined inside action->delete object
        this.$alert({
          type: "warning",
          text: !!action.customDeleteMsg
            ? action.customDeleteMsg
            : this.$t("GENERAL.ACTIONS.DELETE_MESSAGE"),
          confirmButtonText: this.$t("GENERAL.YES"),
          cancelButtonText: this.$t("GENERAL.NO"),
          hasConfirmation: true,
          confirmCallback: () => {
            this.$emit(`${action.name}`, data);
          },
        });
      }
    },
    manageActionsColumn() {
      const isActionsUsed = this.fields.some((field) => field.key === "Actions");
      this.stFields = this.fields;

      if (this.actionsList.length && !isActionsUsed) {
        const actionsRow = { key: "Actions", label: this.actionsLabel };
        if (this.actionsClass) {
          actionsRow.tdClass = this.actionsClass;
        }
        this.stFields.push(actionsRow);
      }
      if (this.hasIconDefined()) {
        this.stFields.unshift({ key: "Icons", label: "" });
      }
    },
    goToLink(data) {
      this.$emit("goToLink", data);
    },
    presenter(data) {
      if (!this?.modelPresenter) return data.value;
      return this.modelPresenter(data.item, data.field.name);
    },
    onRowSelected(items) {
      this.$emit("onRowSelected", items);
    },
    hasIconDefined() {
      return !isObjectEmpty(this.firstColumnIcons);
    },
    onRowClicked(item, index) {
      this.$emit("onRowClicked", { item, index });
    },
    rowClass(item, type) {
      let classes = this.tbodyTrClass;
      // TODO: Abstract
      if (item?.is_extension) {
        classes += " is-child-row";
      }

      if (this.hasSelectedColumnDefined) {
        classes += " selectable";
      }

      return classes;
    },
    checkRow(data) {
      this.$set(this.stItems, data.index, data.item);
      const selectedRows = this.selectedRows(this.stItems);
      const selectableRows = this.selectableRows(this.stItems);
      if (this.checkLimit && selectableRows.length > this.checkLimit) {
        Message.error(this.checkLimitMessage, { number: this.checkLimit });
      }

      if (selectedRows.length < this.stItems.length) {
        this.selectAll = false;
      }
      if (selectedRows.length === this.stItems.length) {
        this.selectAll = true;
      }

      this.$emit("itemChecked", this.checkLimit ? selectableRows : selectedRows);
    },
    selectedRows(data) {
      return data.filter((el) => el.selected && !el.locked);
    },
    lockedRows(data) {
      return data.filter((el) => !el.selected && el.locked);
    },
    selectableRows(data) {
      return data.filter((el) => el.selected && !el.hideSelectable);
    },
    sortingChanged(ctx) {
      if (this.serverSideSorting) {
        const sortingParams = { sortBy: ctx.sortBy, sortDesc: ctx.sortDesc };
        this.setSortingParams({ stateModule: this.stateModule, sortingParams });
      }
    },
    deselectAll(value) {
      if (this.hasSelectedColumnDefined && value) {
        this.selectAll = false;
      }
    },
    showCheckbox(item) {
      return !item?.hideSelectable;
    },
    getColWidth(field) {
      if (field.booleanType && this.customBooleanTypeField) {
        return "80px";
      }
      if (field.doubleSize) {
        return "360px";
      }
      return "";
    },
    closeColumnSelector() {
      this.selectedConfigurableFields = [...this.snapshotSelectedConfigurableFields];
      this.showColumnSelector = false;
    },
    applyColumnSelector() {
      if (this.fields.find((f) => f.key === "Actions")) {
        this.fields.splice(0, this.fields.length - 2);
      } else {
        this.fields.splice(0, this.fields.length - 1);
      }
      this.selectedConfigurableFields.reverse().forEach((key) => {
        this.fields.unshift(this.configurableFields.find((c) => c.key === key));
      });
      this.selectedConfigurableFields = this.selectedConfigurableFields.reverse();
      if (this.persistanceIdentifier) {
        localStorage.setItem(
          `${this.persistanceIdentifier}_${this.currentUser.id}`,
          JSON.stringify(this.selectedConfigurableFields)
        );
      }
      this.showColumnSelector = false;
    },
    handleColumnSelected(checked, key) {
      if (checked) {
        this.selectedConfigurableFields.push(key);
      } else {
        this.selectedConfigurableFields.splice(
          this.selectedConfigurableFields.indexOf(key),
          1
        );
      }
    },
    populateSelectedConfigurableFieldsSnapshot() {
      this.fields
        .filter((f) => f.key !== "Actions" && f.key !== "columnSelector")
        .forEach((c) => {
          this.snapshotSelectedConfigurableFields.push(c.key);
        });
      this.selectedConfigurableFields = [...this.snapshotSelectedConfigurableFields];
    },
  },
  beforeDestroy() {
    // clear sorting fields (if exists)
    this.fields.forEach((field) => {
      if (field.sortable) field.sortable = false;
    });
  },
};
</script>
